function init() {
    'use strict';
    //
}

document.addEventListener('DOMContentLoaded', init);
(function ($) {
    $(document).ready(function () {

        $('.accordion-heading').on('click', function () {
            if ($(this).hasClass('open')) {
                $(this).removeClass('open');
            } else {
                $(this).addClass('open');
            }
            $(this).parent().siblings('.content').slideToggle(250);
        });

    });
})(jQuery);
